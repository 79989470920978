import { DNDFilterState } from "holocene-components/settings/DNDTab";
import { MaterialFilterState } from "holocene-components/settings/MaterialsTab";
import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import { INotificationTypes, useNotification } from "holocene-providers/common";
import { demurrageDetentionService } from "holocene-services/demurrageDetention.service";
import logisticsService from "holocene-services/logistics.service";
import { settingsService } from "holocene-services/settings.service";
import {
  BillingDetail,
  DNDRow,
  IAddDNDRequest,
  IDND,
  CustomerPurchaseMaterial,
} from "holocene-services/settings.service/types";
import { useMemo } from "react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";

export const useGetBillingDetails = (pageSize: number, customerId?: number) => {
  const { data, isLoading, isError, error, fetchNextPage, isFetching } = useInfiniteQuery(
    [QUERY_CONSTANTS.BILLING_DETAILS, customerId],
    ({ pageParam }) =>
      settingsService.getBillingDetails({ customerId, take: pageSize, skip: 0, ...pageParam }),
    { enabled: Boolean(customerId), keepPreviousData: true }
  );

  const allData = useMemo(
    () =>
      data?.pages.reduce((arr: BillingDetail[], { records }) => {
        if (records) {
          arr.push(...records);
        }
        return arr;
      }, []),
    [data?.pages]
  );

  const totalCount = data?.pages[0]?.count;

  return {
    data: allData,
    totalCount,
    isLoading: isLoading || isFetching,
    error,
    isError,
    fetchNextPage,
  };
};
export const useGetDNDData = (filter: DNDFilterState) => {
  const { data, isLoading, isError, error, fetchNextPage, isFetching, refetch } = useInfiniteQuery(
    [QUERY_CONSTANTS.DEMURRAGE_DETEMTION, filter],
    ({ pageParam }) => settingsService.getDNDData({ take: 10, skip: 0, ...pageParam, ...filter }),
    { keepPreviousData: true }
  );

  const allData = useMemo(
    () =>
      data?.pages.reduce((arr: DNDRow[], { demurrageAndDetentions }) => {
        if (demurrageAndDetentions) {
          arr.push(...demurrageAndDetentions);
        }
        return arr;
      }, []),
    [data?.pages]
  );

  const totalCount = data?.pages[0]?.count;

  return {
    data: allData,
    totalCount,
    isLoading: isLoading || isFetching,
    error,
    isError,
    fetchNextPage,
    refetch,
  };
};

export const useUploadMLDoc = () => {
  return useMutation((paylaod: FormData) => settingsService.uploadMLDoc(paylaod));
};

export const useGetLinerOptions = (searchString: string) => {
  return useQuery([QUERY_CONSTANTS.LINER_OPTIONS, searchString], () =>
    settingsService.getLinerOptions(searchString)
  );
};

export const useGetPortOptions = (searchString: string) => {
  return useQuery([QUERY_CONSTANTS.PORT_OPTIONS, searchString], () =>
    settingsService.getPortOptions(searchString)
  );
};

export const useGetLinescapePortOptions = (searchString: string, countryAlpha2Code?: string) => {
  return useQuery([QUERY_CONSTANTS.LINESCAPE_PORT_OPTIONS, searchString], () =>
    settingsService.getSalesPortOptions(searchString, countryAlpha2Code)
  );
};

export const useGetDNDIndividual = (id?: number) => {
  return useQuery(
    [QUERY_CONSTANTS.DEMURRAGE_DETEMTION_INDIVIDUAL, id],
    () => (id ? settingsService.getDNDIndividual(id!) : undefined),
    { enabled: !!id }
  );
};

export const useAddDND = () =>
  useMutation((request: IAddDNDRequest) => settingsService.addDND(request));

export const useUpdateDND = () =>
  useMutation(({ id, request }: { id: number; request: IAddDNDRequest }) =>
    settingsService.updateDND(id, request)
  );

export const useGetAllDocumentTypesForTemplates = (params: { generatable?: boolean }) => {
  const { data, isLoading, isFetching, isError } = useQuery(
    [QUERY_CONSTANTS.ALL_DOCUMENT_TYPES, params],
    () => settingsService.getAllDocumentTypes(params)
  );

  return { data, isLoading: isLoading || isFetching, isError };
};

export const useGetCustomerDocumentTemplateFile = (s3Uri?: string) => {
  const { data, isLoading, isFetching, isError } = useQuery(
    [QUERY_CONSTANTS.CUSTOMER_DOCUMENT_TEMPLATE_FILE, s3Uri],
    () => (s3Uri ? settingsService.getCustomerDocumentTemplateFile(s3Uri) : undefined)
  );

  return { data, isLoading: isLoading || isFetching, isError };
};

export const useGetCustomerDocumentTemplates = () => {
  const { data, isLoading, isFetching, isError } = useQuery(
    QUERY_CONSTANTS.CUSTOMER_DOCUMENT_TEMPLATES,
    settingsService.getCustomerDocumentTemplates
  );

  return { data, isLoading: isLoading || isFetching, isError };
};

export const usePostCustomerDocumentTemplate = () => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();
  const { mutate, isLoading } = useMutation(settingsService.postCustomerDocumentTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_CONSTANTS.CUSTOMER_DOCUMENT_TEMPLATES);
    },
    onError: (err: any) => {
      addNotification({
        type: INotificationTypes.Error,
        message: err?.response?.data?.message || "Failed to upload document template",
      });
    },
  });

  return { mutate, isLoading };
};

export const useDeleteCustomerDocumentTemplate = () => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();
  const { mutate, isLoading } = useMutation(settingsService.deleteCustomerDocumentTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_CONSTANTS.CUSTOMER_DOCUMENT_TEMPLATES);
    },
    onError: (err: any) => {
      addNotification({
        type: INotificationTypes.Error,
        message: err?.response?.data?.message || "Failed to delete document template",
      });
    },
  });

  return { mutate, isLoading };
};

export const useGetPurchaseTemplates = () => {
  return useQuery([QUERY_CONSTANTS.CUSTOMER_PURCHASE_TEMPLATE], () =>
    settingsService.getCustomerPurchaseTemplate()
  );
};