import { WebViewerInstance } from "@pdftron/webviewer";
import { useEffect, useState } from "react";

class GlobalPDFViewer {
  instance: WebViewerInstance | null = null;
  constructor() {}

  async getPdfViewerInstance() {
    if (this.instance) {
      return this.instance;
    }
    try {
      const WebViewer = await import("@pdftron/webviewer");

      const div = document.createElement("div");
      div.style.height = "0px";
      div.style.width = "0px";
      div.style.opacity = "0";
      document.body.appendChild(div);

      const pdfViewerInstance = await WebViewer.default(
        {
          path: "/lib",
          licenseKey: process.env.PDF_TRON_LICENSE,
          preloadWorker: "pdf",
          css: "/lib/ui/pdfTron.css",
        },
        div
      );

      this.instance = pdfViewerInstance;

      return pdfViewerInstance;
    } catch (err) {
      console.error("Error while instantiating pdftron", err);
      return null;
    }
  }
}

const globalPDFViewerInstance = new GlobalPDFViewer();

export const usePdfViewerInstance = () => {
  const [pdfViewerInstance, setPdfViewerInstance] = useState<WebViewerInstance | null>(null);

  useEffect(() => {
    globalPDFViewerInstance.getPdfViewerInstance().then(setPdfViewerInstance);
  }, []);

  return pdfViewerInstance;
};
