import { DNDFilterState } from "holocene-components/settings/DNDTab";
import apiInstance from "holocene-services/axios";
import {
  AllDocumentTypesResponse,
  CustomerDocumentTemplate,
  CustomerPurchaseTemplate,
  DocumentType,
  GetBillingDetailsResponse,
  GetDNDResponse,
  GetLinerOptionsResponse,
  GetPortOptionsResponse,
  IAddDNDRequest,
  IDND,
  PostCustomerPurchaseTemplate,
} from "./types";

class SettingsService {
  async getBillingDetails(params: {
    customerId: number;
    take: number;
    skip: number;
  }): Promise<GetBillingDetailsResponse> {
    return apiInstance
      .get("/customers/billings", {
        params,
      })
      .then((res) => res.data);
  }
  async getDNDData(params: { take: number; skip: number } & DNDFilterState) {
    return apiInstance
      .get("/customers/demurrage-detention", {
        params,
      })
      .then((res) => {
        const { count, demurrageAndDetentions } = res.data as GetDNDResponse;
        return {
          count,
          demurrageAndDetentions: demurrageAndDetentions.flatMap(
            ({ detentionChargablePeriods, ...res }) =>
              detentionChargablePeriods.map((dd) => ({ ...res, chargeablePeriod: dd }))
          ),
        };
      });
  }

  async uploadMLDoc(formdata: FormData) {
    return apiInstance.post("/customers/training-docs/upload", formdata).then((res) => res.data);
  }
  async deleteDNDChargeablePeriod(id: number) {
    const response = await apiInstance.delete(`/customers/chargeable-period/${id}`);
    return response.data;
  }

  async deleteDND(dndId: number, chargeablePeriodId?: number | null) {
    return apiInstance.delete(`/customers/demurrage-detention/${dndId}`, {
      params: { chargeablePeriodId: chargeablePeriodId || undefined },
    });
  }

  async addDND(data: IAddDNDRequest) {
    const response = await apiInstance.post(`/customers/demurrage-detention`, data);
    return response.data as IDND;
  }

  async getLinerOptions(searchString: string) {
    return apiInstance
      .get("/liners", {
        params: {
          take: 200,
          skip: 0,
          searchString,
        },
      })
      .then((res) => {
        const response = res.data as GetLinerOptionsResponse;
        return {
          liners: response.liners.map((liner) => ({
            label: liner.linerName,
            value: liner.linerCode,
          })),
          count: response.count,
        };
      });
  }

  async getPortOptions(searchString: string) {
    return apiInstance
      .get("/ports", { params: { searchString, portType: "seaport" } })
      .then((res) => {
        const response = res.data as GetPortOptionsResponse;
        return {
          ports: response.ports.map((port) => ({
            label: `${port.name} - ${port.code}`,
            value: port.id,
          })),
          count: response.count,
        };
      });
  }

  async getSalesPortOptions(searchString?: string, countryAlpha2Code?: string) {
    return apiInstance
      .get("/ports/sales", { params: { searchString, countryAlpha2Code } })
      .then((res) => {
        const response = res.data as GetPortOptionsResponse;
        return {
          ports: response.ports.map((port) => ({
            label: `${port.name} - ${port.code}`,
            value: port.id,
          })),
          count: response.count,
        };
      });
  }

  async getDNDIndividual(id: number) {
    const response = await apiInstance.get(`/customers/demurrage-detention/${id}`);
    return response.data as IDND;
  }

  async updateDND(id: number, data: any) {
    const response = await apiInstance.put(`/customers/demurrage-detention/${id}`, data);
    return response.data as IDND;
  }

  async getAllDocumentTypes(params: { generatable?: boolean }) {
    return apiInstance
      .get("/customers/all-document-types", { params })
      .then((res) => res.data) as Promise<AllDocumentTypesResponse>;
  }

  async getCustomerDocumentTemplates() {
    return apiInstance.get("/customer-document-templates").then((res) => res.data) as Promise<
      CustomerDocumentTemplate[]
    >;
  }

  async postCustomerDocumentTemplate({
    documentTypeInformationId,
    file,
    countryId,
    format,
    customDocumentNameId,
  }: {
    file: File;
    documentTypeInformationId?: number;
    countryId?: number;
    format?: string;
    customDocumentNameId?: number;
  }) {
    const formData = new FormData();
    formData.append("file", file);
    //By default while posting the status will be pending_review
    return apiInstance.post(`/customer-document-templates`, formData, {
      params: {
        documentTypeInformationId,
        customDocumentNameId,
        countryId,
        format,
        status: "pending_review",
      },
    });
  }

  async deleteCustomerDocumentTemplate({ id }: { id: number }) {
    return apiInstance.delete(`/customer-document-templates/${id}`);
  }

  async getCustomerDocumentTemplateFile(s3Uri: string) {
    return apiInstance
      .get("/customer-document-templates/template-file", { params: { s3Uri } })
      .then((res) => res.data.data) as Promise<ArrayBuffer>;
  }

  async getCustomerPurchaseTemplate() {
    const response = await apiInstance.get("/customer-purchase-template");
    return response.data as {customerTemplates: CustomerPurchaseTemplate[]};
  }

  postCustomerPurchaseTemplate(payload: PostCustomerPurchaseTemplate) {
    return apiInstance.post(`customer-purchase-template/upsert`, payload);
  }
}

export const settingsService = new SettingsService();
