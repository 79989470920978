import { Core, WebViewerInstance } from "@pdftron/webviewer";
import PDF_CONFIG from "holocene-components/workspace/PDFConfig";
import { PDFDocument } from "pdf-lib";

export const updateAvailableActions = ({
  instance,
  isEditAllowed,
  documentType,
  isDraft,
  onPdfSave,
  onDownload,
  loadOptions,
}: {
  instance: WebViewerInstance | undefined;
  isEditAllowed: boolean;
  documentType?: string;
  isDraft?: boolean;
  onPdfSave: () => void;
  onDownload?: () => void;
  loadOptions?: boolean;
}) => {
  if (instance) {
    const isEditable = isEditAllowed;
    instance.UI.disableElements(PDF_CONFIG.editTools);
    if (isDraft) {
      instance.UI.enableElements(PDF_CONFIG.draftEditableTools);
    } else {
      instance.UI.enableElements(PDF_CONFIG.editTools);
    }

    instance.UI.setHeaderItems((header) => {
      const defaultHeaderOptions = header.getItems() as any[];
      const headerItems = [
        defaultHeaderOptions.find((item) => item.title === "component.leftPanel"),
        defaultHeaderOptions.find((item) => item.type === "divider" && !item.hidden),
        defaultHeaderOptions.find((item) => item.title === "Full Screen") ?? {
          type: "actionButton",
          title: "Full Screen",
          img: "../../fullscreen.svg",
          onClick: instance.UI.toggleFullScreen,
        },
        defaultHeaderOptions.find((item) => item.element === "zoomOverlay"),
        defaultHeaderOptions.find((item) => item.type === "divider" && !!item.hidden),
        defaultHeaderOptions.find((item) => item.toolName === "Pan"),
        defaultHeaderOptions.find((item) => item.toolName === "TextSelect"),
        defaultHeaderOptions.find((item) => item.className === "custom-ribbons-container"),
        isEditable && onPdfSave
          ? defaultHeaderOptions.find((item) => item.title === "Save") ?? {
              type: "actionButton",
              title: "Save",
              img: "../../save.svg",
              onClick: onPdfSave,
            }
          : undefined,
        onDownload
          ? {
              type: "actionButton",
              title: "Download",
              img: "../../download.svg",
              onClick: () => {
                onDownload();
              },
            }
          : undefined,
        defaultHeaderOptions.find((item) => item.title === "Print") ?? {
          type: "actionButton",
          title: "Print",
          img: "../../print.svg",
          onClick: () => {
            instance.UI.print();
          },
        },
      ];

      header.update(headerItems.filter(Boolean));
    });
  }
};

export const addSignatureFromUrl = (
  signatureImageSrc: string,
  documentViewer: Core.DocumentViewer
) => {
  if (documentViewer) {
    const signatureTool = documentViewer.getTool("AnnotationCreateSignature");
    fetch(signatureImageSrc)
      .then((response) => response.blob())
      .then((blob) => {
        var reader = new FileReader();
        reader.onload = function () {
          //@ts-ignore
          signatureTool.importSignatures([reader.result as string]);
        };
        reader.readAsDataURL(blob);
      });
  }
};

export const countPages = (file: File): Promise<number> => {
  return new Promise<number>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      if (reader.result) {
        try {
          const pdf = await PDFDocument.load(reader.result);
          resolve(pdf.getPages().length);
        } catch (err) {
          reject(
            "Could not load the document. Please ensure that the file is not encrypted or corrupted."
          );
        }
      }
    };
  });
};

export const preparePdfTronTemplateData = (obj: any) => {
  if (Array.isArray(obj)) {
    const output: any[] = [];

    obj.forEach((entry) => {
      if (typeof entry === "object") {
        output.push(preparePdfTronTemplateData(entry));
      } else {
        output.push(
          entry === null ? undefined : typeof entry === "number" ? entry.toString() : entry
        );
      }
    });
    return output;
  }

  const output: any = {};

  Object.entries(obj).forEach(([key, value]) => {
    const updatedValue =
      value === null
        ? undefined
        : typeof value === "object"
        ? preparePdfTronTemplateData(value)
        : typeof value === "number"
        ? value.toString()
        : value;
    output[key] = updatedValue;
  });
  return output;
};
