import {
  TransportModeNameV2,
  TransportModeOptionV2,
} from "holocene-components/common/new/TransportIcon";
import {
  DocumentTypeEnum,
  LocationCountry,
  TransportationMode,
} from "holocene-services/delivery.service/types";
import {
  InboundDelivery,
  InboundDeliveryDocument,
  InboundDeliveryStatusEnum,
  InboundShipment,
} from "holocene-services/inbound-delivery.service/types";
import { NylasMailMessage, NylasMailThread } from "holocene-services/nylas.service/types";
import { ICountry } from "holocene-services/user.service";

export enum PurchaseOrderGroupByEnum {
  origin = "origin",
  requestedDeliveryDate = "requestedDeliveryDate",
  none = "none",
  supplierName = "supplierName",
}

export enum PurchaseOrderTypeEnum {
  fixed = "fixed",
  recurring = "recurring",
  blanket = "blanket",
}

export interface PurchaseOrderTypes {
  label: string;
  value: string;
}

export interface PurchaseOrderCreation {
  incoterm?: number;
  origin?: number;
  ponumber?: string;
  transportationMode?: TransportModeOptionV2;
  purchaseOrderType?: string;
  poFile?: File;
  paymentTerms?: string;
  incotermLocation?: string;
}

export interface GetPurchaseOrdersQuery {
  groupBy?: PurchaseOrderGroupByEnum;
  supplierName?: string[];
  requestedDeliveryDate?: {
    startDate: string;
    endDate: string;
  };
  confirmedDeliveryDate?: {
    startDate: string;
    endDate: string;
  };
  createdAt?: {
    startDate: string;
    endDate: string;
  };
  origin?: number[];
  // TODO: add steps and alerts
  poType?: PurchaseOrderTypeEnum[];
  incoterm?: string[];
  status?: PurchaseOrderStatusEnum;
  hasDeliveryDateChange?: boolean;
  awaitingSupplierConfirmation?: boolean;
}

export enum PurchaseOrderStatusEnum {
  open = "open",
  confirmed = "confirmed",
  active = "active",
  closed = "closed",
  cancelled = "cancelled",
}

export enum PaymentTermsEnum {
  net30 = "net30",
  net45 = "net45",
  net60 = "net60",
  cash_against_documents = "cash_against_documents",
  end_of_month = "end_of_month",
  before_the_15th_of_the_month = "before_the_15th_of_the_month",
  cash_in_advance = "cash_in_advance",
  letter_of_credit = "letter_of_credit",
  cash_against_delivery = "cash_against_delivery",
}

export const PaymentTermsLabel = {
  [PaymentTermsEnum.net30]: "Net 30",
  [PaymentTermsEnum.net45]: "Net 45",
  [PaymentTermsEnum.net60]: "Net 60",
  [PaymentTermsEnum.cash_against_documents]: "Cash Against Documents",
  [PaymentTermsEnum.end_of_month]: "End of Month",
  [PaymentTermsEnum.before_the_15th_of_the_month]: "Before the 15th of the Month",
  [PaymentTermsEnum.cash_in_advance]: "Cash in Advance",
  [PaymentTermsEnum.letter_of_credit]: "Letter of Credit",
  [PaymentTermsEnum.cash_against_delivery]: "Cash Against Delivery",
};

export enum InboundDeliveryQuantityEnum {
  partial = "partial",
  over = "over",
  full = "full",
}

export interface PurchaseOrderListing {
  id: number;
  customerId: number;
  type: PurchaseOrderTypeEnum;
  originCountryId: number;
  destinationCountryId: number;
  status: PurchaseOrderStatusEnum;
  openedAt: Date | null;
  confirmedAt: Date | null;
  activeAt: Date | null;
  closedAt: Date | null;
  cancelledAt: Date | null;
  paymentTerms: PaymentTermsEnum | null;
  poNumber: string;
  requestedDeliveryDate: Date | null;
  requestedShippingDate: Date | null;
  supplierName: string | null;
  supplierAddress: string | null;
  supplierContact: string | null;
  billToName: string | null;
  billToAddress: string | null;
  deliveryName: string | null;
  deliveryAddress: string | null;
  currency: string | null;
  incotermLocation: string | null;
  deliveryQuantity: InboundDeliveryQuantityEnum | null;
  sumTotalPrice: string | null;
  assignedBuyerUserId: number | null;
  incotermId: number;
  salesOrderId: number | null;
  createdAt: Date;
  updatedAt: Date;
  // Associations
  originCountry: LocationCountry;
  incoterm: { code: string; name: string; id: number };
  // Computed
  deliveryStatus: InboundDeliveryStatusEnum | null;
  isPartial: boolean;
  modeOfTransport?: TransportationMode;
  confirmedDeliveryDate?: string;
  isReadyForShipping?: boolean;
  isConfirmed?: boolean;
}
export interface IGetPurchaseOrdersResponse {
  groups: {
    label: string;
    orders: PurchaseOrderListing[];
  }[];
  totalCount: number;
}

export interface PurchaseOrderDetailsResponse {
  purchaseOrder: IPurchaseOrder;
  confirmedDateHistory: {
    id: number;
    purchaseOrderId: number;
    deliveryDate?: string;
    seen: boolean;
    status?: "late" | "early";
    updateMode: "email" | "manual";
    nylasMessageSummaryId: number;
    createdAt: string;
  }[];
}

export interface PurchaseOrderMailDetailsResponse {
  threads: any;
  summary: {
    summary: string;
    intent: string;
    detail: string | null;
  } | null;
}

export enum PurchaseOrderType {
  fixed = "fixed",
  recurring = "recurring",
  contract = "contract",
}

export interface IPurchaseOrdersTableRow {
  poNumber: string;
  assignedBuyerUserId: string;
  status: string;
  deliveryQuantity: number;
  supplierName: string;
  requestedDeliveryDate: string;
  id: number;
}

export interface PurchaseOrderDocument {
  id: number;
  purchaseDocumentId: number;
  purchaseDocument: PurchaseDocument;
}

export type PurchaseOrderInboundDelivery = InboundDelivery & {
  InboundDeliveryDocument: InboundDeliveryDocument[];
  activeShipment: InboundShipment & { transportationMode: TransportationMode };
};

export interface IPurchaseOrder {
  id: number;
  poNumber: string;
  status: string;
  type?: PurchaseOrderType;
  customerId: number;
  supplierName: string;
  requestedDeliveryDate: Date;
  requestedShippingDate: Date;
  sumTotalPrice: string;
  paymentTerms: string;
  createdAt: Date;
  confirmedAt: Date;
  openedAt: Date;
  activeAt: Date;
  closedAt: Date;
  InboundDelivery: PurchaseOrderInboundDelivery[];
  PurchaseOrderDocument: PurchaseOrderDocument[];
  incotermId: number;
  incoterm: IIncoterm;
  incotermLocation?: string;
  originCountryId: number;
  destinationCountryId: number;
  originCountry: ICountry;
  PurchaseOrderMaterial: [];
  billToAddress?: string;
  deliveryName?: string;
  deliveryAddress?: string;
  supplierAddress?: string;
  isPartial: boolean;
  salesOrderId?: number;
  paymentDueAt?: Date;
  assignedBuyerUserId: number | null;
  currency?: string | null;
}

export interface PurchaseDocument {
  id: number;
  documentTypeInformationId: number;
  documentTypeInformation: { type: DocumentTypeEnum };
  documentTypeCustom?: string;
  extractedValues?: {
    doc_type: string;
    entities: any;
  };
  reclassifiedFrom?: DocumentTypeEnum;
  location?: string;
  isDraft: boolean;
  requestId?: string;
  source: string;
  customerId: number;
  createdAt: string;
  updatedAt: Date;
  purchaseOrder: {
    id: number;
    customerId: number;
    type: string;
    originCountryId: number;
    destinationCountryId: number;
    status: string;
    openedAt: Date;
    confirmedAt: Date;
    activeAt: Date;
    closedAt: Date;
    cancelledAt: Date;
    paymentTerms: string;
    poNumber: string;
    requestedDeliveryDate: Date;
    requestedShippingDate: Date;
    supplierName: string;
    supplierAddress: string;
    supplierContact: number;
    billToName: string;
    billToAddress: string;
    deliveryName: string;
    deliveryAddress: string;
    currency: string;
    incotermLocation: string;
    deliveryQuantity: number;
    sumTotalPrice: string;
    assignedBuyerUserId: number;
    incotermId: number;
    salesOrderId: number;
    createdAt: Date;
    updatedAt: Date;
  };
}

export interface IIncoterm {
  applicableTo: string;
  code: string;
  id: number;
  name: string;
}

export interface GetPurchaseOrderResponse {
  poNumber: string;
  id: number;
  incotermId: number;
  originCountryId: number;
  incoterm: IIncoterm;
}

export enum PurchaseOrderColumnKey {
  poNumber = "poNumber",
  steps = "steps",
  modeOfTransport = "modeOfTransport",
  createdAt = "createdAt",
  incoterm = "incoterm",
  alerts = "alerts",
  supplierName = "supplierName",
  requestedDeliveryDate = "requestedDeliveryDate",
  confirmedDeliveryDate = "confirmedDeliveryDate",
  poType = "poType",
  origin = "origin",
  leadTime = "leadTime",
  user = "user",
}

export const PurchaseOrderStepEnum = {
  open: "open",
  confirmed: "confirmed",
  ready_for_shipping: "ready_for_shipping",
  in_transit: "in_transit",
  delivered: "delivered",
  closed: "closed",
  cancelled: "cancelled",
} as const;

export type PurchaseOrderStepEnum =
  (typeof PurchaseOrderStepEnum)[keyof typeof PurchaseOrderStepEnum];

interface PurchaseLoadPlanItem {
  id: number;
  purchaseLoadPlanId: number;
  color?: string | null;
  name?: string | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  weight?: number | null;
  quantity?: number | null;
  stackable: boolean;
  tiltable: boolean;
}

export interface IPurchaseOrderEntry {
  poNumber: string;
  assignedBuyerUserId: string;
  status: string;
  deliveryQuantity: number;
  supplierName: string;
  requestedDeliveryDate: string;
  id: number;
}

export interface ICreatePurchaseOrderReq {
  incotermCode: string;
  poNumber: string;
  originCountryAlpha2Code: string;
  transportationMode: TransportModeOptionV2;
  document?: string;
}

export interface ICreateInboundDeliveryReq {
  orderId: number;
  incotermCode: string;
  originCountryCode: string;
  transportationMode: TransportModeNameV2;
  requestedDeliveryDate?: Date;
}

export type PurchaseOrderNylasThread = {
  id: number;
  purchaseOrderId: number;
  nylasThreadId: string;
  nylasThreadJson: NylasMailThread;
  createdAt: Date;
};

export type PurchaseOrderNylasThreadMessage = {
  id: number;
  purchaseOrderNylasThreadId: number;
  nylasMessageId: string;
  nylasMessageJson: NylasMailMessage;
  summaryIntent: string | null;
  summaryJson: {
    intent: string; // "Order Delivery Date";
    entities: {
      INTENT: string; // "Order Delivery Date";
      CHANGE: true;
      DELIVERY_DATE_STATUS: string; // "confirmed";
      DELIVERY_DATE: string;
      ORDER_NUMBER: string;
    };
    summary: string;
    detail: string;
  };
  createdAt: Date;
};

export type PurchaseOrderNylasMessageSummary = {
  id: number;
  purchaseOrderId: number;
  inboundDeliveryId?: number;
  nylasMessageId: string;
  intent: string | null;
  summary: string | null;
  deliveryDate: string | null;
  createdAt: Date;
};
export interface PurchaseOrderNylasMessageWithSummary extends NylasMailMessage {
  summary?: PurchaseOrderNylasMessageSummary;
}

export type PurchaseOrderEmailsResponse = {
  threads: NylasMailThread[];
};

export type LinkSalesOrderPayload = {
  purchaseOrderId: number;
  salesOrderId: number;
};

export const ORDER_TIMELINE = {
  RELEASED: "Order Released",
  CONFIRMED: "Confirmed By Supplier",
  READY: "Ready For Shipping",
  IN_TRANSIT: "In Transit",
  DELIVERED: "Delivered",
  GOODS_RECEIVED: "Goods Received",
  CLOSED: "Closed",
};

export type PurchaseTimelineResponse = {
  [key: string]: {
    date: Date | null;
    alert: {
      message: string;
      polarity: "negative" | "positive";
    };
  };
};

export interface IThumbnail {
  id: string | number;
  dataUrl: string;
  name: string;
  extractedName: string;
}

export type ViewableDocument = {
  blob: Blob | File;
  name: string;
  id: number;
  extension?: string;
  documentType: DocumentTypeEnum;
};
