import { AirplaneTilt, Boat, IconProps, Package, Train } from "phosphor-react";
import { AnyTransportMode, TransportModeName, TransportModeNameV2 } from "./constants";
import TruckIcon from "../../../../public/icons/svg/Truck.svg";

export const TransportIcon: React.FC<
  { mode: AnyTransportMode | null; className?: string } & Omit<IconProps, 'mode'>
> = ({ mode, ...restProps }) => {
  switch (mode) {
    case TransportModeName.Air:
      return <AirplaneTilt {...restProps} />;
    case TransportModeNameV2.Rail:
    case TransportModeName.Land:
      return <Train {...restProps} />;
    case TransportModeName.Sea:
      return <Boat {...restProps} />;
    case TransportModeName.Parcel:
      return <Package {...restProps} />;
    case TransportModeNameV2.Road:
      return <TruckIcon {...restProps} />;
    default:
      return null;
  }
};