import { useCallback } from "react";
import {
  useAlertWebSocket,
  WebsocketAlert,
  WebsocketAlertAction,
  WebsocketAlertModelName,
} from "./alertWebSocket.hooks";
import { usePdfViewerInstance } from "./pdfViewer";
import { useGetCustomerDocumentTemplates } from "./settings.hooks";
import { useGetCountries, useGetUserInfo } from "./users.hooks";
import shipmentService from "holocene-services/shipment.service";
import deliveryService from "holocene-services/delivery.service";
import { getDeliveryDetailsInTemplateFormat } from "holocene-utils/delivery.utils";
import {
  DEFAULT_DELIVERY_FIELD_CONFIG_MAPPING,
  formatNumberValuesForDocument,
} from "holocene-components/delivery/DocumentTemplateModal/utils";

export const useGenerateDocumentWebhookListener = () => {
  const pdfViewerInstance = usePdfViewerInstance();
  const { data: customerDocumentTemplates } = useGetCustomerDocumentTemplates();
  const { data: countries } = useGetCountries();
  const { data: user } = useGetUserInfo();

  const handleGenerateDocumentAlert = useCallback(
    async (alert: WebsocketAlert) => {
      if (
        alert.modelName === WebsocketAlertModelName.SALES_AUTOMATIONS &&
        alert.action === WebsocketAlertAction.GENERATE_DOCUMENT
      ) {
        console.log("GENERATING DOCUMENT", alert);
        if (!pdfViewerInstance) {
          console.log("Failed to find PDF viewer instance");
          // Error
          return;
        }
        const salesDeliveryId = alert.salesDeliveryId!;
        const delivery = await deliveryService.getDeliveryDetails(salesDeliveryId);

        // Only the logistics user's account can generate documents
        const canUserTriggerGeneration =
          user?.userId === delivery.OutboundLogistics.logisticsUserId;
        if (!canUserTriggerGeneration) return;

        // === Fetch document template file
        const { documentType } = alert.data as unknown as { documentType: string };
        const possibleTemplates = customerDocumentTemplates?.filter((template) => {
          const matchesDocumentType = template.customDocumentName
            ? template.customDocumentName.name === documentType
            : template.documentTypeInformation.type === documentType;
          const isApproved = template.status === "ready_to_use";
          const matchesCountry =
            !template.country ||
            template.country.id === delivery.OutboundLogistics.deliveryAddressCountryId;

          return matchesDocumentType && isApproved && matchesCountry;
        });

        if (!possibleTemplates?.length) {
          // Handle generation error
          console.log("Could not find document template");
          return;
        }
        const template =
          possibleTemplates.find((template) => !!template.country) || possibleTemplates[0];

        const templateFile = await shipmentService.getFileBlob(template).catch((error) => {
          console.log("ERROR FETCHING FILE", error);
          return null;
        });

        if (!templateFile) return;

        if (template.customerDocumentFormat === "zpl") {
          await deliveryService.generateDeliveryDocument({
            deliveryId: salesDeliveryId,
            documentLabel: template.label,
          });
        } else {
          // === Fetch delivery details and create template values
          const deliveryDetails = await deliveryService.getDeliveryDetails(salesDeliveryId);
          const equipmentLoadPlan = await deliveryService
            .getEquipmentLoadPlan(deliveryDetails.OutboundLogistics.id)
            .catch(() => null);
          const palletLoadPlan = await deliveryService
            .getPalletLoadPlan(deliveryDetails.OutboundLogistics.id)
            .catch(() => null);
          const deliveryTemplateValues = getDeliveryDetailsInTemplateFormat(
            deliveryDetails,
            countries!,
            equipmentLoadPlan,
            palletLoadPlan
          );

          // === Create template document with prefilled values based on delivery details
          const uploadDraftTemplate = new Promise<void>((resolve) => {
            pdfViewerInstance!.Core.documentViewer.addEventListener(
              "documentLoaded",
              async () => {
                try {
                  await pdfViewerInstance!.Core.documentViewer
                    .getDocument()
                    ?.applyTemplateValues(
                      formatNumberValuesForDocument(
                        deliveryTemplateValues,
                        template.templateConfig || DEFAULT_DELIVERY_FIELD_CONFIG_MAPPING
                      )
                    );
                  const documentViewer = pdfViewerInstance!.Core.documentViewer;
                  const doc = documentViewer.getDocument();

                  const fileData = await Promise.race([
                    new Promise((_, reject) => {
                      setTimeout(reject, 20000);
                    }),
                    doc.getFileData({ downloadType: "pdf" }).catch((err) => {
                      console.log("ERROR WHILE EXTRACTING DOC", template.label, err);
                      resolve();
                    }),
                  ]).catch(() => {
                    console.log("DOCUMENT EXTRATION TOOK TOO LONG");
                    return null;
                  });

                  if (!fileData) return;

                  const arr = new Uint8Array(fileData as ArrayBuffer);
                  const blob = new Blob([arr], { type: "application/pdf" });

                  const document = new File([blob], `${template.label}.pdf`, {
                    type: "application/pdf",
                  });
                  // === Upload document to delivery
                  const payload = {
                    deliveryId: alert.salesDeliveryId!,
                    document,
                    documentType: template.documentTypeInformation.type,
                    label: template.customDocumentName?.name,
                    isDraft: true,
                    templateValues: deliveryTemplateValues,
                  };

                  await deliveryService.uploadGeneratedDeliveryDocument(payload);
                  resolve();
                } catch (err) {
                  console.log("FAILED TO APPLY TEMPLATE VALUES FOR", template.label, err);
                  resolve();
                }
              },
              { once: true }
            );

            const errorCallback = (err: any) => {
              // Do something with error. eg. instance.showErrorMessage('An error has occurred')
              pdfViewerInstance!.UI.removeEventListener(
                pdfViewerInstance!.UI.Events.LOAD_ERROR,
                errorCallback
              );
              resolve();
            };
            pdfViewerInstance.UI.addEventListener(
              pdfViewerInstance.UI.Events.LOAD_ERROR,
              errorCallback
            );

            console.log("LOADING DOCUMENT");
            pdfViewerInstance!.UI.loadDocument(templateFile.fileBlob, {
              // @ts-ignore
              officeOptions: { doTemplatePrep: true },
              extension: "docx",
            });
          });

          await uploadDraftTemplate;
        }
      }
    },
    [customerDocumentTemplates, pdfViewerInstance, countries]
  );

  useAlertWebSocket(handleGenerateDocumentAlert);
};
