import {
  BookTransportPreRequisites,
  DeliveryEquipmentLoadPlan,
  DeliveryLoadPlanCalculation,
  IDeliveryDetails,
} from "holocene-services/delivery.service/types";
import { CustomerDetails, ICountry } from "holocene-services/user.service/types";
import { dateUtils, formatStringDate } from "./date.utils";
import moment from "moment";
import { indexBy } from "./common.utils";
import { preparePdfTronTemplateData } from "./pdfTron.utils";

export const getBookTransportationDisabledStatus = (
  prerequisiteData?: BookTransportPreRequisites
) => {
  if (!prerequisiteData) return { disabled: true, message: "" };
  const isBookTransportationLSPConfigured = prerequisiteData?.availableServiceProviders.length > 0;
  const isBookTransportationPrerequisitesFulfilled = prerequisiteData?.fulfilled;
  const disabled =
    !isBookTransportationLSPConfigured || !isBookTransportationPrerequisitesFulfilled;

  const message = !isBookTransportationLSPConfigured
    ? "Currently, you have no logistics provider set."
    : !isBookTransportationPrerequisitesFulfilled
    ? "Required data is not available to Book Transport."
    : "";

  return { disabled, message };
};

export const getLoadPlanDescriptionDetails = (loadPlan?: DeliveryLoadPlanCalculation | null) => {
  if (!loadPlan) return { containersString: "", volume: 0, weight: 0, palletCount: 0 };
  const containerCounts: { [label: string]: number } = {};
  const containerWeights: {
    [label: string]: {
      weight: number;
      count: number;
    };
  } = {};

  let volume = 0;
  let weight = 0;

  loadPlan?.solutions?.[0].containers.forEach((c) => {
    volume += c.volume;
    weight += c.WT || 0;

    if (containerCounts[c.name]) {
      containerCounts[c.name]++;
    } else {
      containerCounts[c.name] = 1;
    }

    if (containerWeights[c.name]) {
      containerWeights[c.name] = {
        weight: containerWeights[c.name].weight + c.WT,
        count: containerWeights[c.name].count++,
      };
    } else {
      containerWeights[c.name] = {
        weight: c.WT,
        count: 1,
      };
    }
  });
  const containersString = Object.entries(containerCounts)
    .map(([name, count]) => `${count} x ${name}`)
    .join(", ");

  return { volume, containersString, weight, containerWeights, containerCounts };
};

export const getBookEmailTemplate = (
  delivery: IDeliveryDetails,
  customer?: CustomerDetails,
  deliveryLoadPlan?: DeliveryEquipmentLoadPlan | null,
  palletLoadPlan?: DeliveryEquipmentLoadPlan | null
) => {
  const { containersString, volume, weight, containerWeights } = getLoadPlanDescriptionDetails(
    deliveryLoadPlan?.equipmentLoadPlan
  );

  const palletCount = palletLoadPlan?.palletLoadPlan?.solutions?.[0]?.containers?.length || 0;
  const unitWt = deliveryLoadPlan?.warehouseData.uomWeight || "";

  const separatedContainerStrings = containersString.split(",");

  const outboundLogistics = delivery.OutboundLogistics;
  return `<div>${
    containersString
      ? `<div><strong>Quantity of containers required:</strong> ${
          separatedContainerStrings.length
        }\n
${separatedContainerStrings.map((str = "") => `${str.trim()}`).join(", <br />")}</div>`
      : ""
  }
          ${volume ? `<p><strong>CBM:</strong> ${volume.toFixed(2)} m<sup>3</sup></p>` : ""}
          ${
            weight || deliveryLoadPlan?.equipmentLoadPlan?.solutions?.length
              ? "<p><strong>Net weight:</strong>"
              : ""
          }${weight ? ` ${weight.toFixed(2)} ${unitWt || ""}</p>` : ""}

${
  !!containerWeights
    ? Object.entries(containerWeights)
        ?.map(
          ([key, value]) =>
            `${value.count} x ${key.trim()} - ${value.weight.toFixed(2)} ${unitWt || ""}`
        )
        .join(", <br />")
    : ""
}

          <p><strong>Description of goods:</strong></p> 
          ${
            delivery.SalesDeliveryProducts?.length === 0
              ? "-"
              : `<p>${delivery.SalesDeliveryProducts.map(
                  (prod) => `${prod.shippingName || prod.productName || ""}`
                ).join(", ")} ${palletCount ? `in ${palletCount} pallets` : ""}</p>`
          }
          
          <p><strong>Pickup location:</strong></p> 
          <p>${customer?.name || ""},</p>\n${
    customer?.companyAddress ? `${customer?.companyAddress},\n` : ""
  }${customer?.addressLine1 ? `${customer.addressLine1},\n` : ""}${
    customer?.city ? `${customer?.city},\n` : ""
  }${
    customer?.country.officialName || customer?.zipcode
      ? `${customer.country.officialName}${customer.country && customer.zipcode ? " - " : ""} ${
          customer.zipcode || ""
        }`
      : ""
  }<p><strong>Pickup date: </strong>${
    delivery.OutboundLogistics.pickupDate
      ? formatStringDate(delivery.OutboundLogistics.pickupDate, undefined, customer?.timezone)
      : formatStringDate(moment().format(), undefined, customer?.timezone)
  }</p>
          
<p><strong>Consignee:</strong></p>${delivery.customerName ? `${delivery.customerName}\n` : ""}${
    delivery.customerAddressVerified ? `${delivery.customerAddressVerified}\n` : ""
  }${delivery.customerAddressCity ? `${delivery.customerAddressCity}\n` : ""}${
    delivery.customerAddressState ? `${delivery.customerAddressState}\n` : ""
  }${
    delivery.customerAddressCountryName || delivery.customerAddressPostalCode
      ? `${delivery.customerAddressCountryName || ""}${
          delivery.customerAddressCountryName && delivery.customerAddressPostalCode ? " - " : ""
        }${delivery.customerAddressPostalCode || ""}`
      : ""
  } 
<p><strong>Notify:</strong></p>${
    outboundLogistics.deliveryAddressVerified
      ? `${outboundLogistics.deliveryAddressVerified || ""}\n`
      : ""
  }${
    outboundLogistics.deliveryAddressVerifiedLine2
      ? `${outboundLogistics.deliveryAddressVerifiedLine2 || ""}\n`
      : ""
  }${
    outboundLogistics.deliveryAddressVerifiedLine3
      ? `${outboundLogistics.deliveryAddressVerifiedLine3 || ""}\n`
      : ""
  }${
    outboundLogistics.deliveryAddressCity ? `${outboundLogistics.deliveryAddressCity || ""}\n` : ""
  }${
    outboundLogistics.deliveryAddressState
      ? `${outboundLogistics.deliveryAddressState || ""}\n`
      : ""
  }${
    outboundLogistics.deliveryAddressCountryName || outboundLogistics.deliveryAddressPostalCode
      ? `${outboundLogistics.deliveryAddressCountryName || ""}${
          outboundLogistics.deliveryAddressCountryName &&
          outboundLogistics.deliveryAddressPostalCode
            ? " - "
            : ""
        }${outboundLogistics.deliveryAddressPostalCode || ""}`
      : ""
  } 
<p><strong>Terms: ${delivery.incoterm?.code || ""} ${delivery.incotermLocation || ""}</strong></p>
          </div>`;
};

const getDateValue = (date?: string | null) =>
  date ? dateUtils.formatDateYYYYMMDD(date) : undefined;

export const getDeliveryDetailsInTemplateFormat = (
  delivery: IDeliveryDetails,
  countries: ICountry[],
  equipmentLoadPlan?: DeliveryEquipmentLoadPlan | null,
  palletLoadPlan?: DeliveryEquipmentLoadPlan | null
) => {
  const equipmentLoadPlanContainers =
    equipmentLoadPlan?.equipmentLoadPlan?.solutions?.[0]?.containers;
  const equipmentLoadPlanTotals = equipmentLoadPlanContainers?.reduce(
    (totals: any, container) => ({
      weight: totals.weight + container.WT || 0,
      volume: totals.volume + container.volume || 0,
      tareWeight: totals.tareWeight + container.tare || 0,
      pallets: totals.pallets + 1,
    }),
    {
      weight: 0,
      volume: 0,
      tareWeight: 0,
      pallets: 0,
    }
  );

  const palletLoadPlanContainers = palletLoadPlan?.palletLoadPlan?.solutions?.[0]?.containers;
  const palleteLoadPlanTotals = palletLoadPlanContainers?.reduce(
    (totals: any, container) => ({
      weight: totals.weight + container.WT || 0,
      volume: totals.volume + container.volume || 0,
      tareWeight: totals.tareWeight + container.tare || 0,
      pallets: totals.pallets + 1,
    }),
    {
      weight: 0,
      volume: 0,
      tareWeight: 0,
      pallets: 0,
    }
  );

  const deliveryAddressCountryName =
    delivery.OutboundLogistics.deliveryAddressCountryName ||
    delivery.OutboundLogistics.deliveryAddressAlpha2Code
      ? countries.find(
          (country) => country.alpha2Code === delivery.OutboundLogistics.deliveryAddressAlpha2Code
        )?.officialName
      : null;

  const salesWarehousePackagingProductMap = indexBy(delivery.SalesWarehouseProducts, (product) =>
    String(product.id)
  );

  const salesWarehousePackaging = delivery.SalesWarehouse.SalesWarehousePackaging.map(
    (packaging) => {
      const products =
        packaging.salesWarehouseProduct?.map(
          (product) => salesWarehousePackagingProductMap[product.salesWarehouseProductId]
        ) || [];

      return {
        ...packaging,
        productName: products.map((product) => product.productName).join(", "),
        productCode: products.map((product) => product.productCode).join(", "),
      };
    }
  );

  const serviceProvider =
    delivery.OutboundLogistics.OutboundLogisticsBooking?.CustomerLogisticsService?.Service;
  const bookingName = serviceProvider
    ? `${serviceProvider.displayName || serviceProvider.name} - ${
        serviceProvider.LogisticsServiceProvider.displayName ||
        serviceProvider.LogisticsServiceProvider.name
      }`
    : undefined;

  return preparePdfTronTemplateData({
    ...delivery,
    createdAt: getDateValue(delivery.createdAt),
    dispatchedAt: getDateValue(delivery.dispatchedAt),
    deliveredAt: getDateValue(delivery.deliveredAt),
    requestedShippingDate: getDateValue(delivery.requestedShippingDate),
    confirmedShippingDate: getDateValue(delivery.confirmedShippingDate),
    SalesWarehouse: {
      ...delivery.SalesWarehouse,
      SalesWarehousePackaging: salesWarehousePackaging,
      pickDate: getDateValue(delivery.SalesWarehouse.pickDate),
      packDate: getDateValue(delivery.SalesWarehouse.packDate),
    },
    OutboundLogistics: {
      ...delivery.OutboundLogistics,
      pickupDate: getDateValue(delivery.OutboundLogistics.pickupDate),
      firstVesselDepartureDate: getDateValue(delivery.OutboundLogistics.firstVesselDepartureDate),
      documentCutoffDate: getDateValue(delivery.OutboundLogistics.documentCutoffDate),
      deliveryAddressCountryName: deliveryAddressCountryName,
      trackingNumbers: delivery.OutboundLogistics.OutboundTracking?.map(
        (tracking) => tracking.trackingNumber
      ).join(", "),
      bookingName: bookingName,
    },
    equipmentLoadPlan: equipmentLoadPlanContainers
      ? {
          totals: equipmentLoadPlanTotals,
          containers: equipmentLoadPlanContainers,
        }
      : undefined,
    palletLoadPlan: palletLoadPlanContainers
      ? {
          totals: palleteLoadPlanTotals,
          containers: palletLoadPlanContainers,
        }
      : undefined,
    dangerousGoods: delivery.SalesDeliveryProducts.filter(
      (delivery) => delivery.stockKeepingUnit?.dangerous
    ),
  });
};
