import Select, { components, MenuPlacement, StylesConfig } from "react-select";
import { CaretDown, X } from "phosphor-react";
import {
  getMultiSelectStyles,
  getSearchableSelectStyles,
  SelectSize,
  SelectVariant,
} from "holocene-components/MultiSelect/constants";
import { clearTooltips, rebuildTooltips } from "holocene-components/common/CustomTooltip";

export interface IOption {
  label: string;
  value: string | number;
}

export interface ISelectProps {
  options: any[];
  label?: string;
  components?: any;
  value?: any | undefined;
  onChange?: (data: any) => void;
  onMenuClose?: (isChanged: boolean) => void;
  disabled?: boolean;
  getOptionLabel?: (option: any) => string;
  register?: any;
  menuPlacement?: MenuPlacement;
  emptyValue?: { label: string; value: string } | null;
  menuPortalTarget?: HTMLElement | null;
  isClearable?: boolean;
  customKey?: string;
  onInputChange?: (value: string) => void;
  inputValue?: string;
  className?: string;
  isLoading?: boolean;
  selectStyles?: StylesConfig;
  isMultiLevelOptions?: boolean;
  blend?: boolean;
  variant?: SelectVariant;
  error?: boolean | string;
  placeholder?: string;
  isMulti?: boolean;
  size?: SelectSize;
  menuShouldBlockScroll?: boolean;
  isOptionDisabled?: (option: any) => boolean;
  labelClassName?: string;
  showTooltips?: boolean;
}

export const Option = (props: any) => {
  const { label, isSelected, data, showTooltips } = props;

  return (
    <div role="option" aria-selected={isSelected} className={"h-[38px]"}>
      <components.Option {...props}>
        <div
          className={"flex items-center"}
          data-tip={showTooltips ? label || data.text : undefined}
        >
          <span className="truncate text-sm">{label || data.text}</span>
        </div>
      </components.Option>
    </div>
  );
};

const CustomSelect = ({
  options,
  label,
  components,
  value,
  onChange,
  disabled,
  getOptionLabel,
  register,
  menuPlacement,
  emptyValue = null,
  menuPortalTarget,
  isClearable = false,
  customKey = "",
  onInputChange,
  className,
  selectStyles,
  isMultiLevelOptions = false,
  blend = false,
  variant = "contained",
  onMenuClose,
  placeholder = "Select",
  error,
  size,
  menuShouldBlockScroll,
  isOptionDisabled,
  labelClassName,
  showTooltips,
}: ISelectProps) => {
  return (
    <div
      className={`single-select ${blend && "blend"} ${className || ""}`}
      data-testid="single-select"
    >
      {typeof label !== "undefined" && (
        <label className={`block text-xs text-secondary-text font-semibold mb-1 ${labelClassName}`}>
          {label}
        </label>
      )}
      <Select
        {...register}
        isDisabled={disabled}
        classNamePrefix="select"
        options={options}
        key={customKey}
        isSearchable={false}
        isClearable={isClearable}
        menuPlacement={menuPlacement}
        components={{
          Option: (props) => <Option {...props} showTooltips={showTooltips} />,
          DropdownIndicator: (state) =>
            isClearable && state.hasValue ? null : (
              <CaretDown className="text-holocene-blue mx-2.5" weight="bold" size={16} />
            ),
          ClearIndicator: (state) => (
            <button
              onClick={(e) => {
                e.stopPropagation();
                state.clearValue();
              }}
            >
              <X className="text-holocene-blue mx-2.5" size={16} />
            </button>
          ),
          ...components,
        }}
        getOptionLabel={(option: any) => {
          if (getOptionLabel) {
            return getOptionLabel(option);
          }
          return option.text || option.label;
        }}
        onMenuOpen={() => showTooltips && setTimeout(rebuildTooltips, 0)}
        onChange={onChange}
        onInputChange={onInputChange}
        styles={{ ...getMultiSelectStyles(variant, size), ...selectStyles }}
        className={!!error ? "border rounded-lg border-danger" : ""}
        value={
          isMultiLevelOptions
            ? value
            : options.find((item) =>
                item.options
                  ? item.options.some((opt: { value: string | number }) => opt.value === value)
                  : item.value === value
              ) || emptyValue
        }
        placeholder={placeholder}
        menuPortalTarget={menuPortalTarget}
        onMenuClose={(isChanged: boolean) => {
          onMenuClose?.(isChanged);
          if (showTooltips) clearTooltips();
        }}
        menuShouldBlockScroll={menuShouldBlockScroll}
        isOptionDisabled={isOptionDisabled}
      />
      {error && <div className="pt-1 left-0 text-danger text-xs">{error}</div>}
    </div>
  );
};

export const SearchableSelect = ({
  options,
  label,
  components,
  value,
  onChange,
  disabled,
  getOptionLabel,
  register,
  menuPlacement,
  emptyValue,
  menuPortalTarget,
  isSearchable = false,
  onInputChange,
  inputValue,
  isLoading,
  isMulti,
  error,
  variant,
  className,
  placeholder = "Select",
}: ISelectProps & { isSearchable?: boolean }) => {
  const values = (() => {
    if (!isMulti) return options.find((item) => item.value === value) || emptyValue;
    return value?.map((val: any) => options.find((item) => item.value === val) || emptyValue);
  })();
  return (
    <div data-testid="single-select">
      {typeof label !== "undefined" && (
        <label className="block text-xs text-secondary-text font-semibold mb-1">{label}</label>
      )}
      <Select
        {...register}
        isDisabled={disabled}
        classNamePrefix="select"
        options={options}
        isClearable={false}
        isSearchable={isSearchable}
        menuPlacement={menuPlacement}
        isLoading={isLoading}
        components={{
          ...components,
          Option: (props) => <Option {...props} />,
          DropdownIndicator: () => <CaretDown className="text-holocene-blue mx-2.5" size={16} />,
        }}
        getOptionLabel={(option: any) => {
          if (getOptionLabel) {
            return getOptionLabel(option);
          }
          return option.text || option.label;
        }}
        onChange={onChange}
        onInputChange={onInputChange}
        inputValue={inputValue}
        styles={getSearchableSelectStyles(variant)}
        value={values}
        placeholder={placeholder}
        isMulti={isMulti}
        menuPortalTarget={menuPortalTarget}
        className={className}
        menuShouldBlockScroll
      />
      {error && (
        <div className="pt-1 left-0 text-danger text-xs overflow-hidden text-ellipsis whitespace-nowrap">
          {error}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
