import { QUERY_CONSTANTS } from "holocene-constants/queryConstants";
import { decodeBase64ToJSON, encodeJSONToBase64, isDeepEqual } from "holocene-utils/helpers";
import { useRouter } from "next/router";
import {
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { useGetCurrentUserInfo } from "./users.hooks";
import { ModuleEnum } from "holocene-components/settings/UserDetailsModal";
import { getLocalStorageItem } from "holocene-utils/common.utils";

//Helps us carry out a function with a debounce interval:
//Once the function is called and timeout is set, subsequent calls to the
//function reset the timeout and queue the function with the most recent arguments.
//After the debounce delay lapses, with no new calls made to the fn, the function is called
//with the last arguments that were passed to it
export const useDebouncedCallback = (fn: (...args: any) => void, delay: number, deps: any[]) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  return useCallback((...args: any) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      fn(...args);
    }, delay);
  }, deps);
};
export const useDebounceValue = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

//Reads the filter state from the url of the page and converts it to a payload.
//Returns the current filter state encoded in the url and a debounced setter function
//that can be called everytime the form values change - similar to useState.
//This hook should be called at the top level of the component to ensure correct results
export function usePersistedFilterState<T>(
  defaultState: T,
  debounceInterval: number = 500
): [T, (values: T) => void] {
  const isLoadedFromUrl = useRef(false);
  const urlFilters = useRef<T>(defaultState);
  const router = useRouter();
  const query = useRef(router.query);
  query.current = router.query;

  if (!isLoadedFromUrl.current) {
    const { filter } = router.query;
    if (filter) {
      urlFilters.current = decodeBase64ToJSON(filter as string);
    }
    isLoadedFromUrl.current = true;
  }

  const setUrlFilterState = useDebouncedCallback(
    (filterState) => {
      if (isLoadedFromUrl.current) {
        const isSameAsOld = isDeepEqual(filterState, urlFilters.current);
        if (!isSameAsOld) {
          let filterString = "";
          const isSameAsDefault = isDeepEqual(filterState, defaultState);
          if (isSameAsDefault) {
            filterString = "";
          } else {
            filterString = encodeJSONToBase64(filterState);
          }
          urlFilters.current = filterState;
          console.log(query.current);
          router.replace({
            query: filterString ? { ...query.current, filter: filterString } : query.current,
          });
        }
      }
    },
    debounceInterval,
    []
  );

  return [urlFilters.current, setUrlFilterState];
}

export const useActiveOperation = () => {
  const queryClient = useQueryClient();
  const { asPath } = useRouter();
  const isSalesPath = asPath.startsWith("/sales");
  const isPurchasePath = asPath.startsWith("/purchase");
  const { data: userInfo } = useGetCurrentUserInfo();
  let currentlyActiveOperation = getLocalStorageItem("activeOperation");
  if (!currentlyActiveOperation && userInfo) {
    const isSupportUser = userInfo?.roles.includes("Admin");
    const hasSalesRole =
      userInfo?.detailInfo.Roles.some(({ Role }) => Role.moduleName === ModuleEnum.sales) ?? false;
    const operationToAssign =
      isSupportUser || hasSalesRole ? ModuleEnum.sales : ModuleEnum.purchase;
    localStorage.setItem("activeOperation", operationToAssign);
    currentlyActiveOperation = operationToAssign;
  }
  const activeOperation = isSalesPath
    ? ModuleEnum.sales
    : isPurchasePath
    ? ModuleEnum.purchase
    : currentlyActiveOperation || ModuleEnum.sales;

  if (currentlyActiveOperation !== activeOperation) {
    queryClient.invalidateQueries(QUERY_CONSTANTS.CURRENT_USER_INFO);
  }

  useEffect(() => {
    if (isSalesPath) {
      localStorage.setItem("activeOperation", ModuleEnum.sales);
    }
    if (isPurchasePath) {
      localStorage.setItem("activeOperation", ModuleEnum.purchase);
    }
  }, [isSalesPath, isPurchasePath]);

  return activeOperation as ModuleEnum;
};

export const useRouterState = (key: string, defaultValue: string) => {
  const router = useRouter();
  const state = router.query[key];

  const setState = (value: string) => {
    router.replace(
      `${router.asPath.split("?")[0]}?${new URLSearchParams({ [key]: value }).toString()}`
    );
  };

  useEffect(() => {
    if (!state) setState(defaultValue);
  }, [state]);

  return [state, setState] as [string, (value: string) => void];
};

export const useRemainingHeight = ({
  ref,
  bottomPadding = 0,
}: {
  ref: RefObject<HTMLDivElement | null>;
  bottomPadding?: number;
}) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const measureHeight = () => {
        const top = ref.current!.getBoundingClientRect().top;
        const remainingHeight = document.body.clientHeight - top - bottomPadding;
        setHeight(remainingHeight);
      };

      measureHeight();
      window.addEventListener("resize", measureHeight);
      return () => {
        window.removeEventListener("resize", measureHeight);
      };
    }
  }, []);

  return height;
};
