"use client";
import Script from "next/script";
import { createContext, ReactNode, useContext, useState } from "react";

export interface IBrowserPrint {
  getDefaultDevice: (
    type: string,
    successCallback: (printer: BrowserPrint.Device) => void,
    errorCallback: (error: string) => void
  ) => void;
  getLocalDevices: (
    callback: (devices: BrowserPrint.Device[]) => void,
    errorCallback: (error: string) => void,
    deviceType: string
  ) => void;
  Device: BrowserPrint.Device;
}

const BrowserPrintContext = createContext<{ browserPrint: IBrowserPrint | null }>({
  browserPrint: null,
});

export const BrowserPrintProvider = ({ children }: { children: ReactNode }) => {
  const [printerInstance, setPrinterInstance] = useState<IBrowserPrint | null>(null);
  const updatePrinterInstance = () => {
    if (typeof window !== "undefined" && window.BrowserPrint) {
      setPrinterInstance(window.BrowserPrint as any as IBrowserPrint);
    }
  };

  return (
    <BrowserPrintContext.Provider value={{ browserPrint: printerInstance }}>
      <Script src="/lib/browser-print/BrowserPrint-3.1.250.min.js" onLoad={updatePrinterInstance} />
      <Script
        src="/lib/browser-print/BrowserPrint-Zebra-1.1.250.min.js"
        onLoad={updatePrinterInstance}
      />
      {children}
    </BrowserPrintContext.Provider>
  );
};

export const useBrowserPrint = () => {
  return useContext(BrowserPrintContext);
};
