import { LargeNumberLike } from "crypto";
import { CountrySelectMenuOption } from "holocene-components/common/CountrySelector";
import { ListingTabConfiguration } from "holocene-components/common/TabbedListingPage/TabComponents/types";
import { ModuleEnum } from "holocene-components/settings/UserDetailsModal";
import { IDashboardCompanySibling } from "holocene-services/dashboard.service/types";
import { TransportationMode } from "holocene-services/delivery.service/types";

export interface IShipmentsDispatchResponse {
  dispatchShipments: IShipmentsDispatchItem[];
  count: number;
}

export interface IShipmentsDispatchItem {
  id: number;
  uniqueIdentifier: string;
  status: string;
  destination: string;
  origin: string;
  coordinate: [number, number];
}

export interface IShipmentQueueResponse {
  shipmentQueue: IShipmentQueueItem[];
  count: number;
}

export interface IShipmentQueueItem {
  uniqueIdentifier: string;
  origin: string;
  destination: string;
}

export interface IMemberData {
  name: string;
  countries: LargeNumberLike;
  shipments: number;
}

export interface ITradePolicy {
  id: number;
  summary: string;
  interventiontype: string;
  origin: string;
  destination: string;
  announcement: string;
  inceptiondate: string;
  hscode?: number[];
  impactcolour: string;
}

export interface IDetailInfo {
  id: number;
  email: string;
  createdAt: string;
  customerId: number;
  nylasAccessToken: string;
  updatedAt: string;
  status: string;
  nylasAccoountId: string;
  name: string;
  profileUrl: string;
  emailReminderPendingShipment: string | null;
  emailTemplate: string | null;
  emailReminderDispatchedShipments: string;
  emailReminderException: true;
  signatureUrl: string;
  Roles: { Role: IUserRole }[];
  UserAssignedFeaturePermissions: {
    FeaturePermission: IFeaturePermission;
    readable: boolean;
    writable: boolean;
  }[];
}

export interface IUserRole {
  displayName: string;
  id: number;
  moduleName: string;
  role: string;
}

export interface IFeaturePermission {
  id: number;
  feature: FeaturePermissionEnum;
  displayName: string;
  moduleName: ModuleEnum;
  readable: boolean;
  writable: boolean;
}

export enum FeaturePermissionEnum {
  DELIVERY = "delivery",
  DISPATCH = "dispatch",
  CONTROL_TOWER = "control_tower",
  PERFORMANCE = "performance",
  TRADE_LANES = "trade_lanes",
  SETTINGS = "settings",
  PURCHASE_ORDERS = "purchase_orders",
  INCOMING_ORDERS = "incoming_orders",
}

export enum AvailableCustomerTypesEnum {
  IMPORTER = "importer",
  EXPORTER = "exporter",
  IMPORTER_EXPORTER = "importer/exporter",
  FREIGHT_FORWARDER = "freight forwarder",
}

export interface CurrentUserInfo {
  sub: string;
  name: string;
  email: string;
  email_verified: true;
  metadata: {};
  roles: string[];
  permissions: string[];
  tenantId: string;
  tenantIds: string[];
  profilePictureUrl: string;
  sid: string;
  type: string;
  aud: string;
  iss: string;
  iat: number;
  exp: number;
  userId: number;

  customer: {
    id: number;
    name: string;
    address: string;
    primaryContact: string;
    contractStartDate: string;
    contractEndDate: null;
    defaultCountry: number;
    customerType: number;
    parentCompany: number;
    triggerEmail: string;
    triggerEmailSubject: string;
    fronteggTenantId: string;
    status: string;
    currency: string;
    shortName: string;
  };
  detailInfo: IDetailInfo;
}

export interface UserTenant {
  name: string;
  fronteggTenantId: string;
  active: boolean;
  shortName: string;
}

export interface ITradePolicyResponse {
  tradePolicies: ITradePolicy[];
  count: number;
}

export interface IUser {
  id: number;
  email?: string;
  customerId?: number;
  profileUrl?: string | null;
  name?: string | null;
}

export interface ICountry {
  id: number;
  alpha2Code: string;
  alpha3Code: string;
  officialName: string;
  stGallenCountryName: string;
  avgClearingTimeInDays: string | null;
}

export interface UpdateUserPayload {
  name?: string;
  emailReminderPSDay?: number;
  emailReminderPSEvent?: string;
  emailReminderDS?: string[];
  emailReminderException?: boolean;
  emailTemplate?: string;
}

export interface UpdateUserInformationPayload {
  name?: string;
  email?: string;
  role?: number;
  countriesHandled?: number[];
  transportationModesHandled?: number[];
  assignedPermissions?: {
    permissionId: number;
    readable: boolean;
    writable: boolean;
  }[];
  assignDeliveriesSales: boolean;
  assignDeliveriesPurchase: boolean;
  moduleName: ModuleEnum;
}

export interface PutAssignedCountriesPayload {
  userId: number;
  countryIds: number[];
}

export interface PutAssignedCountriesResponse {
  id: number;
  userAssignedCounties: { userAssignedCountry: ICountry }[];
}

export interface PutAssignedModesOfTransportationPayload {
  userId: number;
  transportationModeIds: number[];
}

export interface PutAssignedModesOfTransportationResponse {
  id: number;
  UserAssignedModesOfTransportation: { TransportationMode: TransportationMode }[];
}

export interface GetCustomerUserParams {
  skip?: number;
  take?: number;
  countryIds?: string;
  customerIds?: string;
  searchString?: string;
}

export interface CustomerUser {
  userId: number;
  name: string;
  lastName: string;
  email: string;
  customerName: string;
  customerShortName: string;
  status: "active" | "inactive";
  assignDeliveriesSales: boolean;
  assignDeliveriesPurchase: boolean;
  userAssignedCounties: { userAssignedCountry: ICountry }[];
  UserAssignedModesOfTransportation: { TransportationMode: TransportationMode }[];
  roles: { Role: { id: number; displayName: string; moduleName: string } }[];
  permissions: {
    FeaturePermission: {
      id: number;
      displayName: string;
      moduleName: ModuleEnum;
      readable: boolean;
      writable: boolean;
      feature: FeaturePermissionEnum;
    };
    writable: boolean;
    readable: boolean;
  }[];
}

export interface UserSettingsFilterState {
  countryIds: CountrySelectMenuOption[];
  transportationModes: number[];
  roles: number[];
  permissions: number[];
  customerIds: IDashboardCompanySibling[];
  searchString: string;
  moduleName: ModuleEnum;
  skip: number;
  take: number;
}

export interface CustomerDetails {
  id: number;
  triggerEmail: string | null;
  shortName: string;
  name: string;
  triggerEmailSubject: string | null;
  primaryContact: string;
  companyAddress: string;
  addressLine1?: string;
  city?: string;
  zipcode?: string;
  orderStartPoint: string;
  approval: false;
  selectedCustomerTypeId: number;
  timezone?: string;
  contactNumber?: string;
  availableCustomerTypes: {
    id: number;
    type: "importer" | "exporter" | "importer/exporter" | "freight forwarder";
  }[];
  country: {
    id: number;
    alpha2Code: string;
    alpha3Code: string;
    officialName: string;
    unitSystem: string;
    avgClearingTimeInDays: string;
    stGallenCountryName: string;
  };
  currency: string;
  LogisticsServiceProvider: {
    id: number;
    customerId: number;
    serviceId: number;
    transportationModeId: number;
    TransportationMode: {
      id: number;
      type: string;
      createdAt: string;
      updatedAt: string;
    };
    Service: {
      id: number;
      name: string;
      logisticsServiceProviderId: number;
      LogisticsServiceProvider: {
        id: number;
        name: string;
      };
    };
  }[];
}

export interface UserRoleResponse {
  id: number;
  role: string;
  displayName: string;
  moduleName: string;
}

export interface FeaturePermissionResponse {
  id: number;
  feature: string;
  displayName: string;
  readable: boolean;
  writable: boolean;
}

export interface UserTabPayload {
  groupBy?: string;
  customerNames?: string;
  orderIds?: (number | string)[];
  destination?: number[];
  mode?: number[];
  owner?: number[];
  incoterm?: number[];
  alertTypes?: string[];
  step?: string;
  stepStatus?: string;
  requestedShippingDate?: { startDate: string; endDate: string };
  createdAt?: { startDate: string; endDate: string };
  documentCutoffDate?: { startDate: string; endDate: string };
  packDate?: { startDate: string; endDate: string };
  vesselDepartureDate?: { startDate: string; endDate: string };
}

export interface UserTabConfiguration {
  id: number;
  tabConfiguration: ListingTabConfiguration;
}

export interface UserTabConfigurationApi {
  id: number;
  tabConfiguration: string;
}

export type UserTabListingPage =
  | "in_queue"
  | "dispatched"
  | "purchase_orders"
  | "inbound_deliveries"
  | "incoming_orders";
