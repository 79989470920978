import UserAvatar from "holocene-components/common/UserAvatar";
import { Menu, Portal, Transition } from "@headlessui/react";
import { useState } from "react";
import { IUser } from "holocene-services/user.service";
import { MagnifyingGlass } from "phosphor-react";

type Props = {
  disabled: boolean;
  trigger: any;
  container: any;
  name: string;
  userList: IUser[];
  onUserChange: (userId: number) => void;
  email: string;
  showDefaultUserIcon?: boolean;
  showSearchInput?: boolean;
};

export const User = ({
  disabled,
  name,
  trigger,
  container,
  userList,
  onUserChange,
  email,
  showDefaultUserIcon = false,
  showSearchInput,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredUsers = userList.filter((user) => {
    const query = searchQuery.toLowerCase();
    return user.name?.toLowerCase().includes(query) || user.email?.toLowerCase().includes(query);
  });

  if (disabled) {
    return <UserAvatar name={name || email} size={6} />;
  }

  return (
    <Menu>
      <Menu.Button as="div" ref={trigger}>
        <UserAvatar
          name={name || email}
          size={6}
          className="cursor-pointer"
          showDefault={showDefaultUserIcon}
        />
      </Menu.Button>
      <Transition
        enter="transition duration-300 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Portal>
          <Menu.Items
            className="absolute right-0 w-64 mt-2 py-3 bg-white rounded-md shadow-lg z-10 text-primary-text max-h-[300px] overflow-auto"
            ref={container}
          >
            {showSearchInput && (
              <div className="px-3 pb-2 relative">
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full border-0 border-b border-holocene-blue rounded-none py-1 px-0 text-secondary-text placeholder-holocene-blue"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <MagnifyingGlass
                  size={18}
                  className="text-holocene-blue absolute top-2 right-4"
                  weight="bold"
                />
              </div>
            )}

            {filteredUsers.map((option) => (
              <Menu.Item key={option.id}>
                {({ active }) => (
                  <a
                    href="#"
                    className={`flex items-center px-3 py-1 text-sm
                      ${active && "bg-ice-blue-3"} ${
                      email === option.email && "text-holocene-blue font-bold"
                    }`}
                    onClick={() => onUserChange(option.id)}
                  >
                    <UserAvatar name={option.name || option.email || ""} className="mr-2" />
                    <div className="truncate">{option.name || option.email}</div>
                  </a>
                )}
              </Menu.Item>
            ))}

            {filteredUsers.length === 0 && (
              <div className="px-3 py-2 text-sm text-gray-500">No users found</div>
            )}
          </Menu.Items>
        </Portal>
      </Transition>
    </Menu>
  );
};

export default User;
