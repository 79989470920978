const PDF_CONFIG = {
  editTools: [
    "toolbarGroup-Edit",
    "toolbarGroup-FillAndSign",
    "toolbarGroup-Annotate",
    "contentEditButton",
    "toolsHeader",
  ],
  disabledTools: ["toolbarGroup-Shapes", "toolbarGroup-Insert", "toolbarGroup-Forms"],
  draftEditableTools: ["toolbarGroup-FillAndSign", "toolsHeader"],
  theme: "light",
  editableFilesType: ["Packing List", "Commercial Invoice", "Origin Certificate"],
};

export default PDF_CONFIG;

export const SHARED_DOCUMENT_CONFIG = {
  disabledTools: [...PDF_CONFIG.disabledTools, "searchButton", "toggleNotesButton", "viewControlsButton", "toolbarGroup-FillAndSign", "toolbarGroup-Edit", "toolbarGroup-Annotate"],
  theme: PDF_CONFIG.theme,
};