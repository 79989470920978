import { useCallback } from "react";
import {
  useAlertWebSocket,
  WebsocketAlert,
  WebsocketAlertAction,
  WebsocketAlertModelName,
} from "./alertWebSocket.hooks";
import { useBrowserPrint } from "holocene-providers/common/BrowserPrintProvider";
import shipmentService from "holocene-services/shipment.service";
import { useGetUserInfo } from "./users.hooks";
import deliveryService from "holocene-services/delivery.service";

export const usePrintDocumentWebhookListener = () => {
  const { browserPrint } = useBrowserPrint();
  const { data: user } = useGetUserInfo();

  const handlePrintDocumentAlert = useCallback(
    async (alert: WebsocketAlert) => {
      if (
        alert.modelName === WebsocketAlertModelName.SALES_AUTOMATIONS &&
        alert.action === WebsocketAlertAction.PRINT_DOCUMENT
      ) {
        console.log("PRINT DOCUMENT", alert);
        const salesDeliveryId = alert.salesDeliveryId!;
        const delivery = await deliveryService.getDeliveryDetails(salesDeliveryId);
        
        
        console.log("USERx", user);
        console.log("delivery.OutboundLogistics", delivery.OutboundLogistics);

        // Only the logistics user's account can print documents
        const canUserTriggerPrint = user?.userId === delivery.OutboundLogistics.logisticsUserId;

        console.log("canUserTriggerPrint", canUserTriggerPrint);
        if (!canUserTriggerPrint) return;

        const alertData = alert.data as any as { location: string }[];

        const device = await new Promise<BrowserPrint.Device>((res) => {
          BrowserPrint.getDefaultDevice(
            "printer",
            (device) => {
              res(device);
            },
            (error) => {
              console.log("Failed to find printer", error);
              return;
            }
          );
        });
        if (!device) {
          console.log("DEVICE NOT FOUND");
          return;
        }
        for (const document of alertData) {
          try {
            const content = await shipmentService.getShipmentDocumentsFile(document.location);
            const extension = document.location.split(".").pop();

            if (extension === "pdf") {
              const array = new Uint8Array(content.data);
              const fileBlob = new Blob([array], { type: "application/pdf" });

              device.convertAndSendFile(
                fileBlob,
                () => {
                  console.log("PDF file converted and printed");
                },
                (error) => {
                  console.log("Failed to print PDF file", error);
                }
              );
            } else {
              const fileContent = await new Promise<string>((resolve, reject) => {
                const array = new Uint8Array(content.data);
                const fileBlob = new Blob([array], { type: "text/plain" });

                const reader = new FileReader();
                reader.onload = () => {
                  resolve(reader.result as string);
                };
                reader.onerror = () => reject(reader.error);
                reader.readAsText(fileBlob);
              });

              device.send(
                fileContent,
                () => {
                  console.log("Printing completed successfully");
                },
                (error) => {
                  console.error("Error sending ZPL to printer", error);
                }
              );
            }
          } catch (err) {
            console.log("Failed to print document", err);
          }
        }
      }
    },
    [browserPrint, user]
  );

  useAlertWebSocket(handlePrintDocumentAlert);

  return null;
};
